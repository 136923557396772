
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })
  
    // *****************************************************************************************************************
    export default class DevAPIs extends Vue {
        // Data function
        public data() {
            return {
                metalsResponse:
                    '{' + '\n' +
                    '    "base": USD,' + '\n' +
                    '    "date": "2019-04-10",' + '\n' +
                    '    "rates": {' + '\n' +
                    '        "Gold (USDXAU)": 1,292.80,' + '\n' +
                    '        "Silver (USDXAG)": 15.19,' + '\n' +
                    '        "Platinum (USDXPT)": 951.60,' + '\n' +
                    '        "Palladium (USDXPD)": 1615.09,' + '\n' +
                    '        "Rhodium (USDXRH)": 5000.00,' + '\n' +
                    '        [170 world currencies and metals]' + '\n' +
                    '    }' + '\n' +
                    '}' + '\n',

                systemsIconPathAndName:
                    require("@/assets/images/icon_code.png"),
            };
        }
    }
